import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/collection-point-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  generalInfo: {
    id: "generalInfo",
    title: "panel.tab.generalInfo",
  },
  status: {
    id: "status",
    title: "panel.tab.status",
  },
  location: {
    id: "location",
    title: "panel.tab.location",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.COLLECTION_POINT,
  name: "panel.title",
  createTabs: [tabs.generalInfo, tabs.status, tabs.location],
  updateTabs: [tabs.generalInfo, tabs.status, tabs.location],
};

export const collectionPointActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
