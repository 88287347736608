/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/naming-convention */

import { isDev } from "~/utils/environment";
import {
  addNameSpaceResources,
  translate,
  useLocalization,
} from "~/utils/i18n/localization";
import { I18nOptions } from "~/utils/i18n/localize";

import {
  BuildingType,
  BusAttendantViolationComplianceType,
  CountryCode,
  DriverStatus,
  VehicleSafetyEventCause,
} from "~/gql/main/types.generated";

import { coreConfigs } from "~/core/config";

type TabTitleKeys = keyof typeof labels;

export const labels = {
  "panel.button.add": {
    en: "Add",
    ar: "إضافة",
  },
  "panel.button.export": {
    en: "Export to csv",
    ar: "",
  },
  "field.user.firstName": {
    en: "First Name",
    ar: "الاسم الأول",
  },
  "field.user.middleName": {
    en: "Middle Name (Optional)",
    ar: "الاسم الأوسط (اختياري)",
  },
  "field.user.lastName": {
    en: "Last Name",
    ar: "الاسم الأخير",
  },
  "field.user.firstNameAr": {
    en: "First Name (Ar)",
    ar: "الاسم الأول",
  },
  "field.user.middleNameAr": {
    en: "Middle Name (Ar) (Optional)",
    ar: "الاسم الأوسط (اختياري)",
  },
  "field.user.lastNameAr": {
    en: "Last Name (Ar)",
    ar: "الاسم الأخير",
  },
  "field.user.country.code": {
    en: "Country code",
    ar: "",
  },
  "field.user.phoneNumber": {
    en: "Phone number",
    ar: "رقم الهاتف",
  },
  "field.user.email": {
    en: "Email address",
    ar: "البريد الإلكتروني",
  },
  "field.user.dateOfBirth": {
    en: "Date of birth",
    ar: "تاريخ الميلاد",
  },
  "field.address.country.code": {
    en: "Country",
    ar: "الدولة",
  },
  // Default administrative area is label if no country code is provided
  "field.address.administrativeArea": {
    en: "Governorate",
    ar: "المحافظة",
  },
  [`field.address.administrativeArea.${CountryCode.OMN}` as const]: {
    en: "Governorate",
    ar: "المحافظة",
  },
  [`field.address.administrativeArea.${CountryCode.KWT}` as const]: {
    en: "Governorate",
    ar: "المحافظة",
  },
  [`field.address.administrativeArea.${CountryCode.ARE}` as const]: {
    en: "Emirate",
    ar: "الإمارة",
  },
  [`field.address.administrativeArea.${CountryCode.SAU}` as const]: {
    en: "City",
    ar: "المدينة",
  },
  // Default locality is label if no country code is provided
  "field.address.locality": {
    en: "Area",
    ar: "المنطقة",
  },
  [`field.address.locality.${CountryCode.OMN}` as const]: {
    en: "Willayah",
    ar: "الولاية",
  },
  [`field.address.locality.${CountryCode.SAU}` as const]: {
    en: "District",
    ar: "المنطقة",
  },
  // Default sub-locality is label if no country code is provided
  "field.address.subLocality": {
    en: "Area",
    ar: "المنطقة",
  },
  [`field.address.subLocality.${CountryCode.OMN}` as const]: {
    en: "Area",
    ar: "المنطقة",
  },
  [`field.address.subLocality.${CountryCode.KWT}` as const]: {
    en: "Area",
    ar: "المنطقة",
  },
  [`field.address.subLocality.${CountryCode.ARE}` as const]: {
    en: "Area",
    ar: "المنطقة",
  },
  [`field.address.subLocality.${CountryCode.SAU}` as const]: {
    en: "Neighborhood",
    ar: "الحي",
  },
  "field.address.buildingType": {
    en: "Building Type",
    ar: "نوع المبنى",
  },
  "field.address.block": {
    en: "Block",
    ar: "الكتلة",
  },
  [`field.address.buildingType.${BuildingType.APARTMENT}` as const]: {
    en: "Apartment",
    ar: "شقة",
  },
  [`field.address.buildingType.${BuildingType.HOUSE}` as const]: {
    en: "House",
    ar: "منزل",
  },
  [`field.address.buildingType.${BuildingType.SCHOOL}` as const]: {
    en: "School",
    ar: "مدرسة",
  },
  "field.address.street": {
    en: "Street",
    ar: "الشارع",
  },
  "field.address.building": {
    en: "Building/House Number",
    ar: "رقم المبنى/المنزل",
  },
  "field.address.floor": {
    en: "Floor",
    ar: "الدور",
  },
  "field.address.flat": {
    en: "Flat",
    ar: "الشقة",
  },
  "field.address.zone": {
    en: "Zone",
    ar: "منطقة",
  },
  "section.tag": {
    en: "Smart Tag",
    ar: "العلامة الذكية",
  },
  "section.newCompliant": {
    en: "New Complaint",
    ar: "شكوة جديدة",
  },
  "selection.travelTags": {
    en: "Select smart tag to assign",
    ar: "حدد العلامة الذكية لتعيينها",
  },
  "selection.travelTags.noTagsAssigned": {
    en: "No smart tags assigned",
    ar: "لم يتم تعيين علامات ذكية",
  },
  "selection.travelTags.noTagsAssignedInstruction": {
    en: "To assign a smart tag, select one from the field above and click on the {{assignButtonLabel}} button.",
    ar: "لتعيين علامة ذكية، اختر واحدة من الحقل أعلاه واضغط على زر {{assignButtonLabel}}.",
  },
  "section.status": {
    en: "Driver Status",
    ar: "حالة السائق",
  },
  "field.status": {
    en: "Status",
    ar: "الحالة",
  },
  [`field.status.${DriverStatus.ACTIVE}` as const]: {
    en: "Active",
    ar: "نشط",
  },
  [`field.status.${DriverStatus.INACTIVE}` as const]: {
    en: "Inactive",
    ar: "غير نشط",
  },
  [`field.status.${DriverStatus.ONBOARDING}` as const]: {
    en: "Onboarding",
    ar: "تحت التوصيل",
  },
  [`field.status.${DriverStatus.POOL}` as const]: {
    en: "Pool",
    ar: "مجموعة",
  },
  "field.incidentTypes": {
    en: "IncidentTypes",
    ar: "أنواع الحوادث",
  },
  [`field.compliance.types.${BusAttendantViolationComplianceType.ABSENCE}` as const]:
    {
      en: "Absence",
      ar: "غائب",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.ABSENCE_EVENING_SHIFT}` as const]:
    {
      en: "Absence evening shift",
      ar: "وردية الغياب المسائية",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.ABSENCE_MORNING_SHIFT}` as const]:
    {
      en: "Absence morning shift",
      ar: "دوام الغياب الصباحي",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.ALLOWING_EAT_AND_FOOD}` as const]:
    {
      en: "allowing eat and food",
      ar: "السماح للأكل والطعام",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.ATTITUDE_MANNERS}` as const]:
    {
      en: "Attitude manners",
      ar: "آداب السلوك",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.DELAY}` as const]:
    {
      en: "Delay",
      ar: "تأخير",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.KID_HAND_OVER_TO_STRANGERS}` as const]:
    {
      en: "Kid hand over to strangers",
      ar: "تسليم طفل للغرباء",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.STUDENT_PICK_AND_DROP}` as const]:
    {
      en: "Student pick and drop",
      ar: "اختيار الطالب وإفلاته",
    },
  [`field.compliance.types.${BusAttendantViolationComplianceType.TAKING_PHOTOS_OF_STUDENT}` as const]:
    {
      en: "Taking photos of students",
      ar: "التقاط صور للطلاب",
    },
  "field.incidentDate": {
    en: "IncidentDate",
    ar: "تاريخ الحوادث",
  },
  "field.incidentTime": {
    en: "IncidentTime",
    ar: "وقت الحوادث",
  },
  "field.note": {
    en: "Note",
    ar: "ملاحظة",
  },
  "field.tag.delivered": {
    en: "Delivered",
    ar: "تم التوصيل",
  },
  "field.tag.active": {
    en: "Active",
    ar: "نشط",
  },
  "button.assignTag": {
    en: "Assign Tag",
    ar: "تعيين العلامة",
  },
  "button.action.viewDetails": {
    en: "View Details",
    ar: "",
  },
  "button.action.duplicate": {
    en: "Duplicate",
    ar: "نسخ",
  },
  "button.action.more": {
    en: "More",
    ar: "",
  },
  "button.action.edit": {
    en: "Edit",
    ar: "",
  },
  "field.quickAddEdit.name": {
    en: "Name",
    ar: "الاسم",
  },
  "field.quickAddEdit.nameAr": {
    en: "Name (AR)",
    ar: "الاسم باللغة العربيه",
  },
  "field.quickAddEdit.swift": {
    en: "Swift",
    ar: "سويفت",
  },
  "field.school": {
    en: "School",
    ar: "المدرسة",
  },
  "field.schools": {
    en: "Schools",
    ar: "المدارس",
  },
  "field.school.schoolGroup.name": {
    en: "School Group",
    ar: "مجموعة المدرسة",
  },
  "field.school.schoolGroup.nameAr": {
    en: "School Group (ar)",
    ar: "مجموعة المدرسة (ar)",
  },
  "field.school.schoolGroup.code": {
    en: "School Group Code",
    ar: "رقم مجموعة المدرسة",
  },
  "field.school.type.name": {
    en: "School Type",
    ar: "نوع المدرسة",
  },
  "field.school.type.nameAr": {
    en: "School Type (ar)",
    ar: "نوع المدرسة (ar)",
  },
  "field.school.stages.name": {
    en: "School Stages",
    ar: "مراحل المدرسة",
  },
  "field.school.stages.nameAr": {
    en: "School Stages (ar)",
    ar: "مراحل المدرسة (ar)",
  },
  "field.school.classes.className": {
    en: "Class Name",
    ar: "اسم الصف",
  },
  "field.school.classes.classNameAr": {
    en: "Class Name (ar)",
    ar: "اسم الصف (ar)",
  },
  "field.sponsor.firstName": {
    en: "First Name",
    ar: "الاسم الأول",
  },
  "field.sponsor.lastName": {
    en: "Last Name",
    ar: "الاسم الأخير",
  },
  "field.sponsor.firstNameAr": {
    en: "First Name (Arabic)",
    ar: "الاسم الأول (عربي)",
  },
  "field.sponsor.lastNameAr": {
    en: "Last Name (Arabic)",
    ar: "الاسم الأخير (عربي)",
  },
  "field.sponsor.phone": {
    en: "Phone Number",
    ar: "رقم الهاتف",
  },
  "field.sponsor.email": {
    en: "Email",
    ar: "البريد الإلكتروني",
  },
  "error.message.isRequiredField": {
    en: "{{fieldLabel}} is required",
    ar: "مطلوب {{fieldLabel}}",
  },
  "error.message.inArabicLetters": {
    en: "Only Arabic text is accepted",
    ar: "يتم قبول فقط النص العربي",
  },
  "error.message.inEnglishLetters": {
    en: "Only English text is accepted",
    ar: "يتم قبول فقط النص الإنجليزي",
  },
  "error.message.shouldBeAPhoneNumber": {
    en: "Should be a phone number with a country code ex: +968********",
    ar: "يجب أن يكون رقم الهاتف مع رقم الدولة بالإضافة إلى الصفر بعد الرقم الأرقام الأربعة الأخيرة",
  },
  "error.message.phoneNumberIsInvalid": {
    en: "Phone number is invalid",
    ar: "",
  },
  "error.message.phoneNumberMin": {
    en: `Phone number must be at least {{phoneMinLength}} digits`,
    ar: `يجب أن يكون رقم الهاتف أكثر من {{phoneMinLength}} أرقام`,
  },
  "error.message.field.exists": {
    en: "{{name}} exists",
    ar: "",
  },
  "error.message.invalidFormat": {
    en: "{{fieldName}} is invalid",
    ar: "",
  },
  "error.message.optionExists": {
    en: "This option already exists",
    ar: "",
  },
  "modal.message.submitting": {
    en: "Submitting...",
    ar: "يتم الارسال...",
  },
  "modal.message.validationError": {
    en: "Please check the fields as some contain errors",
    ar: "برجاء مراجعة البيانات لوجود أخطاء",
  },
  "field.map.HowToSetMarker": {
    en: "To set the marker, right click on the map",
    ar: "لتعيين العلامة ، انقر بزر الفأرة الأيمن على الخريطة",
  },
  "menu.button.createWorkspace": {
    en: "Add Workspace",
    ar: "إضافة مكان العمل",
  },
  "field.workspace.name": {
    en: "Workspace Name",
    ar: "اسم مكان العمل",
  },
  "field.workspace.icon": {
    en: "Workspace Icon",
    ar: "أيقونة مكان العمل",
  },
  "field.workspace.icon.helperText": {
    en: "Enter icon name. Check the following link:",
    ar: "أدخل اسم الأيقونة. تحقق من الرابط التالي:",
  },
  "modal.workspace.button.createWorkspace": {
    en: "Add Workspace",
    ar: "إضافة مكان العمل",
  },
  "modal.workspace.button.updateWorkspace": {
    en: "Edit Workspace",
    ar: "تعديل مكان العمل",
  },
  "modal.workspace.button.removeWorkspace": {
    en: "Delete Workspace",
    ar: "حذف مكان العمل",
  },
  "modal.workspace.button.Workspace": {
    en: "Edit Workspace",
    ar: "تعديل مكان العمل",
  },
  "modal.workspace.button.cancel": {
    en: "Cancel",
    ar: "إلغاء",
  },
  "modal.workspace.muiIcons": {
    en: "MUI Icons",
    ar: "أيقونات MUI",
  },
  "error.message.shouldNotIncludeSpecialChar": {
    en: "Should not include arabic letters or special characters",
    ar: "يجب ان لا تحتوي الحروف علي علامات مميزة",
  },
  "error.message.shouldBeANumber": {
    en: "Must be a number",
    ar: "يجب ان يكون رقم",
  },
  "error.message.invalidEmailFormat": {
    en: "Invalid email format",
    ar: "البريد الإلكتروني غير صحيح",
  },
  "error.message.field.min": {
    en: `{{name}}  must have at least {{limit}} characters`,
    ar: `يجب أن يكون {{name}} أكثر من {{limit}}`,
  },
  "error.message.field.max": {
    en: `{{name}} must not be greater than {{limit}} characters`,
    ar: `يجب أن يكون {{name}} أقل من {{limit}}`,
  },
  "error.message.invalidDateRange": {
    en: "{{name}} should between 1990 and Today’s year",
    ar: "{{name}} يجب أن يكون التاريخ بين سنة 1990 وتاريخ اليوم ",
  },
  "error.message.somethingWentWrong": {
    en: "Something went wrong",
    ar: "",
  },
  "button.add": {
    en: "Add",
    ar: "إضافة",
  },
  "button.edit": {
    en: "Edit",
    ar: "تعديل",
  },
  "chip.direction.home": {
    en: "Home",
    ar: "الصفحة الرئيسية",
  },
  "chip.direction.school": {
    en: "School",
    ar: "المدرسة",
  },
  "select.noOptions": {
    en: "No options found",
    ar: "لا توجد خيارات",
  },
  "select.noOptions.noSearch": {
    en: "No options found. Modify your search to get results",
    ar: "لم يتم العثور على خيارات. قم بتعديل بحثك للحصول على النتائج",
  },
  "select.xItemsSelected": {
    en: "{{count}} items selected",
    ar: "{{count}} تم اختيارها",
  },
  "select.parentPhone": {
    en: "Parent Phone",
    ar: "رقم جوال الأم",
  },
  "button.saveChanges": {
    en: "Save Changes",
    ar: "حفظ التغييرات",
  },
  "label.quickAddEdit.addItem": {
    en: "New Item",
    ar: "",
  },
  "label.quickAddEdit.editItem": {
    en: "Edit Item (changes are global!)",
    ar: "تعديل العنصر (التغييرات على الجميع!)",
  },
  "panel.noPermission": {
    en: "You don't have permission to access this panel",
    ar: "ليس لديك صلاحية للدخول إلى هذا اللوحة",
  },
  "label.unnamedOption": {
    en: "Unnamed Option",
    ar: "خيار بلا اسم",
  },
  "modal.confirm.title": {
    en: "Confirmation",
    ar: "تأكيد",
  },
  "modal.confirm.desc": {
    en: "Are you sure?",
    ar: "هل أنت متأكد؟",
  },
  "modal.button.cancel": {
    en: "Cancel",
    ar: "إلغاء",
  },
  "modal.button.confirm": {
    en: "Confirm",
    ar: "تأكيد",
  },
  "button.addComplaint": {
    en: "Add Complaint",
    ar: " اضافه امتثال",
  },
  "modal.button.assign": {
    en: "Assign",
    ar: "سجل",
  },
  "panel.button.bulkAssignAgents": {
    en: "Bulk Assign Agents",
    ar: "وكلاء التخصيص المجمع",
  },
  "panel.assignmentDate": {
    en: "Assignment Date",
    ar: "تاريخ التكليف",
  },
  "modal.appVersionUpdate.button.refresh": {
    en: "Refresh Now",
    ar: "تحديث",
  },
  "modal.appVersionUpdate.button.later": {
    en: "Later",
    ar: "لاحقا",
  },
  "modal.appVersionUpdate.button.later.waitMinutes": {
    en: "Wait ({{minutes}} minutes)",
    ar: "انتظر ({{minutes}} دقيقة)",
  },
  "modal.appVersionUpdate.button.later.waitSeconds": {
    en: "Wait ({{seconds}} seconds)",
    ar: "انتظر ({{seconds}} ثانية)",
  },
  "modal.appVersionUpdate.newVersion": {
    en: "New Version Available",
    ar: "يوجد إصدار جديد",
  },
  "modal.appVersionUpdate.updateMessage": {
    en: "Please refresh the page to get the latest version ({{version}}) of the dashboard or click on later to wait for {{minutes}} minutes until page refresh automatically.",
    ar: "يرجى تحديث الصفحة للحصول على أحدث إصدار ({{version}}) من لوحة التحكم أو انقر فوق لاحقًا للانتظار لمدة {{minutes}} دقيقة حتى يتم تحديث الصفحة تلقائيًا.",
  },
  "modal.appVersionUpdate.refreshPageInMinutes": {
    en: "Reload page in {{minutes}} minutes",
    ar: "سيتم تحديث الصفحة خلال {{minutes}} دقيقة",
  },
  "modal.appVersionUpdate.refreshPageInSeconds": {
    en: "Reload page in {{seconds}} seconds",
    ar: "سيتم تحديث الصفحة خلال {{seconds}} ثانية",
  },
  "panel.filter.title": {
    en: "Filters",
    ar: "الفلاتر",
  },
  "panel.filter.reset": {
    en: "Reset Filters",
    ar: "إعادة تعيين الفلاتر",
  },
  "panel.filter.apply": {
    en: "Apply",
    ar: "تطبيق",
  },
  "panel.filter.close": {
    en: "Close",
    ar: "إغلاق",
  },
  "panel.filter.field.search": {
    en: "Search",
    ar: "بحث",
  },
  "table.phone": {
    en: "Phone Number",
    ar: "رقم الهاتف",
  },
  "panel.column.title": {
    en: "Customize Columns",
    ar: "",
  },
  "panel.column.reset": {
    en: "Reset to default",
    ar: "",
  },
  "panel.column.apply": {
    en: "Apply",
    ar: "",
  },
  "field.column.showAll": {
    en: "Show All",
    ar: "",
  },
  "table.name": {
    en: "Name",
    ar: "الاسم",
  },
  "table.country": {
    en: "Country",
    ar: "البلد",
  },
  "table.tagIdNumber": {
    en: "Tag ID number",
    ar: "رقم البطاقة",
  },
  "table.tagIdType": {
    en: "Tag ID type",
    ar: "نوع البطاقة",
  },
  "table.routingStatus": {
    en: "Routing Status",
    ar: "حالة الطرق",
  },
  "table.tagId": {
    en: "Tag Id",
    ar: "معرف العلامة",
  },
  "table.depositPaidAt": {
    en: "Deposit Paid At",
    ar: "تاريخ دفع المقدم",
  },
  "table.lastInstallmentPaidAt": {
    en: "Last Installment Paid At",
    ar: "تاريخ دفع اخر قسط",
  },
  "table.lastInstallmentTitle": {
    en: "Last Installment Title",
    ar: "عنوان دفع اخر قسط",
  },
  "table.route": {
    en: "Routes",
    ar: "الطرق",
  },
  "table.submittedAt": {
    en: "Created at",
    ar: "وقت التسجيل",
  },

  "table.studentName": {
    en: "Student Name",
    ar: "اسم الطالب",
  },
  "table.studentId": {
    en: "Id",
    ar: "رقم",
  },
  "table.student.dateOfBirth": {
    en: "Date of birth",
    ar: "تاريخ الميلاد",
  },
  "table.subscription": {
    en: "Subscription",
    ar: "الاشتراك",
  },
  "table.parents": {
    en: "Parents",
    ar: "الآباء",
  },
  "table.parentContactNumber": {
    en: "Parent Contact Number",
    ar: "",
  },
  "table.actions": {
    en: "Actions",
    ar: "",
  },
  "table.internalStatus": {
    en: "Status",
    ar: "الحالة",
  },
  "table.school": {
    en: "School",
    ar: "المدرسة",
  },
  "table.scheduleGroup": {
    en: "Schedule Group",
    ar: "جدول المجموعة",
  },
  "table.approvalStatus": {
    en: "Approval Status",
    ar: "حالة القبول",
  },
  "table.class": {
    en: "Class",
    ar: "الصف",
  },
  "table.schoolType": {
    en: "School Type",
    ar: "نوع المدرسة",
  },
  "table.directionality": {
    en: "Directionality",
    ar: "الاتجاه",
  },
  "table.registrationDate": {
    en: "Registration Date",
    ar: "تاريخ التسجيل",
  },
  "table.overview": {
    en: "Overview",
    ar: "نظرة عامة",
  },
  "table.typeName": {
    en: "Type",
    ar: "النوع",
  },
  "table.licensePlate": {
    en: "License Plate",
    ar: "رقم اللوحة",
  },
  "table.status": {
    en: "Status",
    ar: "الحالة",
  },
  "table.lastSeen": {
    en: "Last Seen",
    ar: "آخر مرة",
  },
  "table.lastLocation": {
    en: "Last Location",
    ar: "آخر موقع",
  },
  "table.driver": {
    en: "Driver",
    ar: "السائق",
  },
  "table.driverPhone": {
    en: "Phone",
    ar: "الهاتف",
  },
  "table.routes": {
    en: "Routes",
    ar: "الطرق",
  },
  "table.vehicleGroup": {
    en: "Group",
    ar: "المجموعة",
  },
  "table.code": {
    en: "Vehicle Number",
    ar: "رقم المركبة",
  },
  "table.year": {
    en: "Year",
    ar: "سنة",
  },
  "table.make": {
    en: "Make",
    ar: "الصنع",
  },
  "table.model": {
    en: "Model",
    ar: "الموديل",
  },
  "table.email": {
    en: "Email",
    ar: "البريد الالكتروني",
  },
  "table.schoolName": {
    en: "Name",
    ar: "الاسم",
  },
  "table.countryName": {
    en: "Country",
    ar: "",
  },
  "table.studentsCount": {
    en: "Active Student",
    ar: "",
  },
  "table.routesCount": {
    en: "Student route assignment",
    ar: "",
  },
  "table.semestersCount": {
    en: "Total Number of semester",
    ar: "",
  },
  "table.schoolCode": {
    en: "School code",
    ar: "",
  },
  "table.manifest": {
    en: "Manifest",
    ar: "",
  },
  "table.routeGroup": {
    en: "Route Group",
    ar: "مجموعة الطريق",
  },
  "table.numSchools": {
    en: "# Schools",
    ar: "# المدارس",
  },
  "table.numStudents": {
    en: "# Students",
    ar: "# الطلاب",
  },
  "table.plannedDuration": {
    en: "Planned Duration",
    ar: "المدة المخططة",
  },
  "table.plannedStartTime": {
    en: "Planned Start Time",
    ar: "وقت البدء المخطط",
  },
  "table.plannedEndTime": {
    en: "Planned End Time",
    ar: "وقت الانتهاء المخطط",
  },
  "table.estimatedTime": {
    en: "Total Trip Time",
    ar: "",
  },
  "table.estimatedDistance": {
    en: "Total Trip Distance",
    ar: "",
  },
  "table.startDate": {
    en: "Start Date",
    ar: "تاريخ البدء",
  },
  "table.endDate": {
    en: "End Date",
    ar: "تاريخ الانتهاء",
  },
  "table.vehicles": {
    en: "Vehicles",
    ar: "الحافلات",
  },
  "table.busAttendants": {
    en: "Bus Attendants",
    ar: "موظفو الحافلات",
  },
  "table.drivers": {
    en: "Bus Drivers",
    ar: "سائق الحافله",
  },
  "table.agents": {
    en: "Agents",
    ar: "عملاء",
  },
  "table.agentUserId": {
    en: "ID",
    ar: "",
  },
  "table.leaveStartDate": {
    en: "Leave Start Date",
    ar: "",
  },
  "table.leaveEndDate": {
    en: "Leave End Date",
    ar: "",
  },
  "table.submissionDate": {
    en: "Submission Date",
    ar: "",
  },
  "table.submittedBy": {
    en: "Submitted by",
    ar: "",
  },
  "table.creditSpent": {
    en: "Credit Spent",
    ar: "أنفق الائتمان",
  },
  "table.currentCredit": {
    en: "Current Credit",
    ar: "الائتمان الجاري",
  },
  "table.cashableCredit": {
    en: "Cashable Credit",
    ar: "ائتمان قابل للصرف",
  },
  "table.nonCashableCredit": {
    en: "Non-Cashable Credit",
    ar: "ائتمان غير قابل للصرف",
  },
  "table.event": {
    en: "Event",
    ar: "حدث",
  },
  "table.dateTime": {
    en: "Date/Time",
    ar: "التاريخ / الوقت",
  },
  "table.vehicleNumber": {
    en: "Vehicle number",
    ar: "",
  },
  "field.vehicleGroup": {
    en: "Owner",
    ar: "مالك الحافلة",
  },
  "table.packageNumber": {
    en: "Package Number",
    ar: "رقم الحزمة",
  },
  "field.contractType": {
    en: "Contract Type",
    ar: "نوع العقد",
  },
  "field.startDate": {
    en: "Start Date",
    ar: "تاريخ البدء",
  },
  "field.originalStartDate": {
    en: "Original Start Date",
    ar: "تاريخ البدء الأصلي",
  },
  "field.terminationDate": {
    en: "Termination Date",
    ar: "تاريخ الإنهاء",
  },
  [`field.contractType.FULLTIME` as const]: {
    en: "Full Time",
    ar: "دوام كامل",
  },
  [`field.contractType.PART_TIME` as const]: {
    en: "Part Time",
    ar: "دوام جزئي",
  },
  [`field.contractType.TEMPORARY` as const]: {
    en: "Temporary",
    ar: "موقت",
  },
  "field.bankBeneficiaryName": {
    en: "Bank Beneficiary Name",
    ar: "اسم البنك المستفيد",
  },
  "field.bankAccountName": {
    en: "Bank Account Name",
    ar: "اسم الحساب المصرفي",
  },
  "field.bankAccountNumber": {
    en: "Bank Account Number",
    ar: "رقم الحساب المصرفي",
  },
  "field.swiftCode": {
    en: "Swift Code",
    ar: "رمز السرعة",
  },
  "field.bank": {
    en: "Bank",
    ar: "المصرف",
  },
  "field.bank.name": {
    en: "Bank Name",
    ar: "اسم البنك",
  },
  "field.bank.nameAr": {
    en: "Bank Name (AR)",
    ar: "اسم البنك (AR)",
  },
  "field.bank.swift": {
    en: "Swift",
    ar: "سويفت",
  },
  "field.compliance.error.msg": {
    en: "The above fields should be filled",
    ar: "يجب ملء الحقول أعلاه",
  },
  "panel.tab.hoursWorked": {
    en: "Hours Worked",
    ar: "ساعات العمل",
  },
  "section.hoursWorkedInfo": {
    en: "Hours Worked Info",
    ar: "معلومات ساعات العمل",
  },
  "section.hoursWorkedHistory": {
    en: "Hours Worked History",
    ar: "تاريخ ساعات العمل",
  },
  "section.workingDate": {
    en: "Working date",
    ar: "معلومات ساعات العمل",
  },
  "section.workingTime": {
    en: "Working time",
    ar: "معلومات ساعات العمل",
  },
  "field.agentName": {
    en: "Agent name",
    ar: "اسم العميل",
  },
  "field.from": {
    en: "From",
    ar: "من",
  },
  "field.to": {
    en: "To",
    ar: "إلى",
  },
  "section.cancellation.confirmCancellationQuestion": {
    en: "Are you sure you want to cancel?",
    ar: "هل أنت متأكد أنك تريد إلغاء؟",
  },
  "modal.submit.errorMessage.unAbleToAdd": {
    en: "Unable to Add",
    ar: "غير قادر على الإضافة",
  },
  "modal.submit.errorMessage.errorsDescriptionMessage": {
    en: "The form contains {{errorsCount}} errors that need to be resolved. Please check highlighted sections fields.",
    ar: "يحتوي النموذج على {{errorsCount}} من الأخطاء التي يجب حلها. يرجى التحقق من حقول الأقسام المميزة.",
  },
  "modal.cancellation.confirmCancellationQuestion": {
    en: "Are you sure you want to cancel?",
    ar: "هل أنت متأكد أنك تريد إلغاء؟",
  },
  "modal.cancellation.loseInfo": {
    en: "It looks like you're still working on this. You will lose the info you've entered so far.",
    ar: "يبدو أنك ما زلت تعمل على هذا. ستفقد المعلومات التي أدخلتها حتى الآن.",
  },
  "modal.button.noKeepEditing": {
    en: "No, Keep Editing",
    ar: "لا ، استمر في التحرير",
  },
  "modal.button.yesCancel": {
    en: "Yes, Cancel",
    ar: "نعم إلغاء",
  },
  "modal.button.backToEditing": {
    en: "Back To Editing",
    ar: "العودة إلى التحرير",
  },
  "section.cancellation.warning": {
    en: "This action cannot be undone",
    ar: "",
  },
  "modal.button.goBack": {
    en: "No, go back",
    ar: "",
  },
  "modal.button.confirm.cancellation": {
    en: "Yes, confirm",
    ar: "تأكيد الإلغاء",
  },
  "feedback.success.single.write": {
    en: "The {{entityName}} has been successfully added",
    ar: "تمت إضافة {{entityName}} بنجاح",
  },
  "feedback.success.single.update": {
    en: "The {{entityName}} has been successfully updated",
    ar: "تم تحديث {{entityName}} بنجاح",
  },
  "feedback.success.single.archive": {
    en: "The {{entityName}} has been successfully archived",
    ar: "تمت أرشفة {{entityName}} بنجاح",
  },

  "feedback.success.bulk.write": {
    en: "{{quantity}} {{entityName}} has been successfully added",
    ar: "تمت إضافة {{quantity}} {{entityName}} بنجاح",
  },
  "feedback.success.bulk.update": {
    en: "{{quantity}} {{entityName}} has been successfully updated",
    ar: "تم تحديث {{quantity}} {{entityName}} بنجاح",
  },
  "feedback.success.bulk.archive": {
    en: "{{quantity}} {{entityName}} has been successfully archived",
    ar: "تم أرشفة {{quantity}} {{entityName}} بنجاح",
  },

  "feedback.error.single.write": {
    en: "The {{entityName}} cannot be added",
    ar: "لا يمكن إضافة {{entityName}}",
  },
  "feedback.error.single.update": {
    en: "The {{entityName}} cannot be updated",
    ar: "لا يمكن تحديث {{entityName}}",
  },
  "feedback.error.single.archive": {
    en: "The {{entityName}} cannot be archived",
    ar: "لا يمكن أرشفة {{entityName}}",
  },

  "feedback.error.bulk.write": {
    en: "{{quantity}} {{entityName}} cannot be added",
    ar: "{{quantity}} لا يمكن إضافة {{entityName}}",
  },
  "feedback.error.bulk.update": {
    en: "{{quantity}} {{entityName}} cannot be updated",
    ar: "لا يمكن تحديث {{quantity}} {{entityName}}",
  },
  "feedback.error.bulk.archive": {
    en: "{{quantity}} {{entityName}} cannot be archived",
    ar: "{{quantity}} لا يمكن أرشفة {{entityName}}",
  },
  "button.moreMenu.columns": {
    en: "Columns",
    ar: "أعدادات",
  },
  "emptyState.message.noRecords": {
    en: `There are currently no {{panelName}} records`,
    ar: "لا يوجد حاليا أي سجلات",
  },
  "emptyState.message.filtersNoRecords": {
    en: `No records match this filter(s) criteria. Use different criteria or clear filters to show results.`,
    ar: "لا توجد سجلات تطابق هذه المعايير ",
  },
  "emptyState.message.searchNoRecords": {
    en: `No records match your search {{search}}. Make sure the spelling is correct or use different keywords.`,
    ar: "لا توجد سجلات تطابق هذه المعايير ",
  },
  "emptyState.message.networkError": {
    en: `Couldn't connect to server. Check your network connection and try again.`,
    ar: "تعذر الاتصال بالخادم. تحقق من اتصالك بالشبكة وحاول مرة أخرى.",
  },
  "emptyState.message.noPermission": {
    en: "You don't have permission to access this panel",
    ar: "ليس لديك صلاحية للدخول إلى هذا اللوحة",
  },
  "emptyState.action.defaultLabel": {
    en: "Add {{panelName}}",
    ar: "اضف",
  },
  "emptyState.action.clearSearch": {
    en: "Clear Search",
    ar: "مسح البحث",
  },
  "emptyState.action.clearFilter": {
    en: "Clear Filter",
    ar: "",
  },
  "emptyState.action.reload": {
    en: "Reload",
    ar: "",
  },
  "networkStatusDetection.noInternetConnection": {
    en: "No internet connection",
    ar: "لا يوجد اتصال بالإنترنت",
  },
  "networkStatusDetection.internetConnectionIsBack": {
    en: "Internet connection is back",
    ar: "تم عودة الاتصال بالانترنت",
  },
  "field.filter.options.all": {
    en: "All",
    ar: "الكل",
  },
  "field.busAttendant": {
    en: "Bus Attendant",
    ar: "موظف الحافلة",
  },
  "field.driver": {
    en: "Driver",
    ar: "السائق",
  },
  "field.serviceName": {
    en: "Service Name",
    ar: "اسم الخدمة",
  },
  "field.provider": {
    en: "Provider Name",
    ar: "اسم المزود",
  },
  "field.serviceProviderGroup": {
    en: "Service Provider Group",
    ar: "",
  },
  "field.vehicle": {
    en: "Vehicle",
    ar: "مركبة",
  },
  "field.route": {
    en: "Route",
    ar: "الطريق",
  },
  "field.routeGroup": {
    en: "Route Group",
    ar: "مجموعة الطريق",
  },
  "field.class": {
    en: "Class",
    ar: "الصف",
  },
  "field.scheduleGroup": {
    en: "School hours",
    ar: "ساعات المدرسة",
  },
  "field.student": {
    en: "Select existing student.",
    ar: "حدد طالب موجود",
  },
  "section.no.route.assign": {
    en: "No routes is assigned",
    ar: "",
  },
  "label.language": {
    en: "Language",
    ar: "اللغة",
  },
  "language.name.en": {
    en: "English",
    ar: "الانجليزية",
  },
  "language.name.ar": {
    en: "Arabic",
    ar: "العربية",
  },
  "label.adjustColumns": { en: "Adjust Columns", ar: "ضبط الأعمدة" },
  "field.uploadFile.chooseFile": { en: "Choose file", ar: "اختر ملف" },
  "field.uploadFile.weSupportFile": {
    en: "We support PNG, JPEG & PDF.",
    ar: "",
  },
  "button.close": { en: "CLOSE", ar: "قريب" },
  "label.feedback.connectionErrorOccurred": {
    en: "Connection error has occurred",
    ar: "",
  },
  "label.cropPhoto": { en: "Crop Photo", ar: "قص الصورة" },
  "label.customizeColumns": { en: "Customize Columns", ar: "تخصيص الأعمدة" },
  "button.done": { en: "Done", ar: "منتهي" },
  "field.uploadFile.dragAndDropHere": {
    en: "Drag and drop here",
    ar: "سحب وإسقاط هنا",
  },
  "button.export": { en: "Export", ar: "يصدّر" },
  "modal.button.keepEditing": { en: "Keep Editing", ar: "استمر في التحرير" },
  "label.letsGetStarted": { en: "Let's get started", ar: "" },
  "label.loading": { en: "Loading...", ar: "تحميل..." },
  "label.loginWithEmushrifEmailAccount": {
    en: "Login with your eMushrif email account.",
    ar: "",
  },
  "button.loginWithGoogle": { en: "Login with Google", ar: "" },
  "menu.logout": { en: "Logout", ar: "تسجيل خروج" },
  "modal.lossAllProgress": {
    en: "You will lose all your progress. This can’t be undone.",
    ar: "ستفقد كل تقدمك. لا يمكن التراجع عن هذا.",
  },
  "file.size.mb": { en: "MB.", ar: "ميغا بايت" },
  "field.nameTooLong": {
    en: "{{name}} must not be greater than {{limit}} characters",
    ar: "",
  },
  "field.nameTooShort": {
    en: "{{name}}  must have at least {{limit}} characters",
    ar: "",
  },
  "field.uploadFile.noImageUploaded": {
    en: "No Image Uploaded",
    ar: "لم يتم تحميل أي صورة",
  },
  "label.optional": {
    en: "Optional",
    ar: "",
  },
  "page.pageNotFound": {
    en: "Page not found",
    ar: "",
  },
  "field.recordAddedSuccessfully": {
    en: "The {{entityName}} has been successfully added",
    ar: "",
  },
  "field.recordArchivedSuccessfully": {
    en: "The {{entityName}} has been successfully archived",
    ar: "",
  },
  "field.recordBulkAddedSuccessfully": {
    en: "{{quantity}} {{entityName}} has been successfully added",
    ar: "",
  },
  "field.recordBulkArchivedSuccessfully": {
    en: "{{quantity}} {{entityName}} has been successfully archived",
    ar: "",
  },
  "field.recordBulkCannotEdited": {
    en: "{{quantity}} {{entityName}} cannot be edited",
    ar: "",
  },
  "field.recordBulkEditedSuccessfully": {
    en: "{{quantity}} {{entityName}} has been successfully edited",
    ar: "",
  },
  "field.recordBulkUpdatedSuccessfully": {
    en: "{{quantity}} {{entityName}} has been successfully updated",
    ar: "",
  },
  "field.recordCannotAdded": {
    en: "The {{entityName}} cannot be added",
    ar: "",
  },
  "field.recordCannotArchived": {
    en: "The {{entityName}} cannot be archived",
    ar: "",
  },
  "field.recordCannotBulkAdded": {
    en: "{{quantity}} {{entityName}} cannot be added",
    ar: "",
  },
  "field.recordCannotBulkArchived": {
    en: "{{quantity}} {{entityName}} cannot be archived",
    ar: "",
  },
  "field.recordCannotBulkUpdated": {
    en: "{{quantity}} {{entityName}} cannot be updated",
    ar: "",
  },
  "field.recordCannotEdited": {
    en: "The {{entityName}} cannot be edited",
    ar: "",
  },
  "field.recordCannotUpdated": {
    en: "The {{entityName}} cannot be updated",
    ar: "",
  },
  "field.recordEditedSuccessfully": {
    en: "The {{entityName}} has been successfully edited",
    ar: "",
  },
  "field.recordUpdatedSuccessfully": {
    en: "The {{entityName}} has been successfully updated",
    ar: "",
  },
  "app.saar": { en: "Saar", ar: "" },
  "headCells.selectedItem": { en: "Selected Item(s)", ar: "العناصر المحددة" },
  "field.uploadFile.serverError": { en: "Server Error", ar: "" },
  "field.uploadFile.somethingWentWrong": {
    en: "Something went wrong.",
    ar: "هناك خطأ ما.",
  },
  "field.uploadFile.tryAgain": { en: "Try again", ar: "حاول مرة أخري" },
  "field.uploadFile.uploadingFile": { en: "Uploading file", ar: "تحميل الملف" },
  "field.uploadFile.uploadingPhoto": {
    en: "Uploading photo",
    ar: "تحميل الصورة",
  },
  "field.uploadFile.cancel": {
    en: "Cancel",
    ar: "إلغاء",
  },
  "modal.wantToCancel": {
    en: "Are you sure you want to cancel?",
    ar: "هل أنت متأكد أنك تريد إلغاء؟",
  },
  "field.uploadFile.browse": {
    en: "BROWSE",
    ar: "يتصفح",
  },
  "field.uploadFile.or": {
    en: "or",
    ar: "",
  },
  "field.uploadFile.dragFileHere": {
    en: "Drag file here",
    ar: "اسحب الملف هنا",
  },
  "field.uploadFile.delete": {
    en: "Delete",
    ar: "",
  },
  "field.uploadFile.areYouSureToDeleteThisFile": {
    en: "Are you sure to delete this file",
    ar: "",
  },
  "error.tryAgain": {
    en: "Try Again",
    ar: "حاول مرة اخري",
  },
  "error.opsSomethingWentWrong": {
    en: "Oops! Something went wrong.",
    ar: "عفوا! هناك شئ غير صحيح.",
  },
  "field.roles": {
    en: "Role",
    ar: "",
  },
  "field.collectionPoint": {
    en: "Collection point",
    ar: "",
  },
  "field.location": {
    en: "Location",
    ar: "",
  },
  // DO NOT DELETE THE FOLLOWING KEY
  // The following is a workaround to fix the issue of the missing translation for dynamic keys
  "field.user.DYNAMIC": {
    en: "{{name}}",
    ar: "{{name}}",
  },
  "icon.copyToClipboard.copyText": {
    en: "Copy Text",
    ar: "",
  },
  "icon.copyToClipboard.copied": {
    en: "Copied",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.CARD}` as const]: {
    en: "Card",
    ar: "بطاقة",
  },
  [`alert.cause.${VehicleSafetyEventCause.COMMAND}` as const]: {
    en: "Command",
    ar: "أمر",
  },
  [`alert.cause.${VehicleSafetyEventCause.IGNITION}` as const]: {
    en: "Ignition",
    ar: "اشتعال",
  },
  [`alert.cause.${VehicleSafetyEventCause.INVALID}` as const]: {
    en: "Invalid",
    ar: "غير صالحة",
  },
  [`alert.cause.${VehicleSafetyEventCause.MOTION_MAJOR}` as const]: {
    en: "Motion major",
    ar: "رئيسي الحركة",
  },
  [`alert.cause.${VehicleSafetyEventCause.MOTION_MINOR}` as const]: {
    en: "Motion minor",
    ar: "حركة طفيفة",
  },
  [`alert.cause.${VehicleSafetyEventCause.NO_CHECK}` as const]: {
    en: "No check",
    ar: "لا الاختيار",
  },
  [`alert.cause.${VehicleSafetyEventCause.PUSHBUTTON}` as const]: {
    en: "Pushbutton",
    ar: "اضغط الزر",
  },
  [`alert.cause.${VehicleSafetyEventCause.TIMEOUT}` as const]: {
    en: "Timeout",
    ar: "نفذ الوقت",
  },
  [`alert.cause.${VehicleSafetyEventCause.PANICBUTTON}` as const]: {
    en: "Panic button",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.SMOKE}` as const]: {
    en: "Smoke Detector",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.HIGH_TEMPERATURE}` as const]: {
    en: "High Temperature",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.HIGH_HUMIDITY}` as const]: {
    en: "High Humidity",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.OVER_SPEED}` as const]: {
    en: "Over Speed",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.BAD_GPS}` as const]: {
    en: "Bad GPS",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.CH_OFFLINE}` as const]: {
    en: "CH offline",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.COLLISION}` as const]: {
    en: "Collision",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.DEVICE_OFFLINE}` as const]: {
    en: "Device offline",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.LOW_SPEED}` as const]: {
    en: "Low speed",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.LOW_VOLTAGE}` as const]: {
    en: "Low voltage",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.NO_DISK}` as const]: {
    en: "No disk",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.RAPID_SPEEDDOWN}` as const]: {
    en: "Rapid speed down",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.RAPID_SPEEDUP}` as const]: {
    en: "Rapid speed up",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.RAPID_TURN}` as const]: {
    en: "Rapid turn",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.TURNOVER}` as const]: {
    en: "Turnover",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.VIDEO_LOSS}` as const]: {
    en: "Video loss",
    ar: "",
  },
  [`alert.cause.${VehicleSafetyEventCause.VIDEO_TAMPERING}` as const]: {
    en: "Video tampering",
    ar: "",
  },
  "field.collectionPoint.tooltip.statusActive": {
    en: "Active",
    ar: "نشط",
  },
  "field.collectionPoint.tooltip.statusInactive": {
    en: "Inactive",
    ar: "غير نشط",
  },
} as const;

export const getCopy = function (
  key: TabTitleKeys,
  options?: I18nOptions
): string {
  if (isDev) {
    addNameSpaceResources(coreConfigs.id, labels);
  }
  // eslint-disable-next-line id-length
  return translate(key, { ns: coreConfigs.id, ...options });
};
export default function useCopy() {
  if (isDev) {
    addNameSpaceResources(coreConfigs.id, labels);
  }
  return useLocalization<TabTitleKeys>(coreConfigs.id);
}
